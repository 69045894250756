import React from 'react';

const Section3 = () => {
  return (
    <div className="h-100 flex flex-col justify-center items-center space-y-4">
      <p>INVEST IN Multiverse AI Layer:</p>
      <p></p>
      <p></p>
    </div>
  );
};

export default Section3;
