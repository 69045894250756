import React from 'react';
import HeaderAnimation from './HeaderAnimation';
import headerLogo from './headerLogo.png'; 
import './Header.css'; 

const Header = () => {
  return (
    <div className="header-wrapper h-100 flex justify-center items-center relative">
      <HeaderAnimation />

      <img 
        src={headerLogo} 
        alt="Header Logo" 
        className="header-logo absolute"
      />

      <div className="npc-layer absolute text-white text-2xl">
        NPC LAYER
      </div>

      <div className="content-box border border-black bg-gray-500 p-4 rounded-lg relative z-10 flex flex-col justify-center items-center">
        <h1 className="text-2xl font-bold mb-3 text-center">BTC Multiverse AI Layer: Outsmart Reality</h1>
        <p className="mb-4 text-center">Experience the edge-cutting, groundbreaking, permissionless, supreme & world-first AI-powered 'Proof of NPC' magic. Not just Web3 reimagined, it's decentralization hosted on AWS. Reality optional, buzz mandatory!</p>
        <div className="flex gap-4">
          <a href="https://t.me/NPCLAYER" target="_blank" rel="noopener noreferrer" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300">Telegram</a>
          <a href="https://twitter.com/NPCLAYER" target="_blank" rel="noopener noreferrer" className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-300">Twitter</a>
        </div>
      </div>
    </div>
  );
};

export default Header;
