import React from 'react';

const Footer = () => {
  return (
    <div className="h-100 flex justify-center items-center">
      <p>SKIP FIN</p>
    </div>
  );
};

export default Footer;
