
import React, { useEffect, useState } from 'react';
// Import your images
import Image1 from './image1.png';
import Image2 from './image2.png';
import Image3 from './image3.png';
import Image4 from './image4.png';
import './Section2.css'; 

const Section2 = () => {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      // Capture vertical scroll, adjust based on your needs
      setScrollY(window.scrollY);
    };

    // Attach event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup on unmount
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Adjust styles based on scrollY
  const dynamicStyle = (base, rate) => ({
    transform: `translateY(${scrollY * rate}px)`,
  });

  return (
<div className="section-container">
<p>SKIP WORK</p>
  <img src={Image1} alt="Description" className="img1" style={dynamicStyle(100, 0.1)} />
  <img src={Image2} alt="Description" className="img2" style={dynamicStyle(200, 0.2)} />
  <img src={Image3} alt="Description" className="img3" style={dynamicStyle(300, 0.3)} />
  <img src={Image4} alt="Description" className="img4" style={dynamicStyle(400, 0.4)} />
</div>
 
  );
};

export default Section2;
