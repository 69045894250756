import React from 'react';

const Section5 = () => {
  return (
    <div className="h-100 flex justify-center items-center">
      <p>SKIP FRENDS | JOIN US</p>
    </div>
  );
};

export default Section5;
