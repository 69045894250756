import React from 'react';
import './App.css';
import Menu from './comp/Menu';
import Header from './comp/Header';
import Section1 from './comp/Section1';
import Section2 from './comp/Section2';
import Section3 from './comp/Section3';
import Section4 from './comp/Section4';
import Section5 from './comp/Section5';
import Footer from './comp/Footer';

function App() {
  return (
    <>
      <Menu />
      <div className="App">
        <Header />
        <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Footer />
      </div>
    </>
  );
}

export default App;
