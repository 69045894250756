import React from 'react';

const Menu = () => {
  return (
    <div className="sticky top-0 bg-black text-white p-4 z-50 w-full">
      <nav className="flex justify-center">
        <ul className="flex flex-wrap justify-center gap-4">
          <li><a href="#section1">SKIP</a></li>
          <li><a href="#section2">MAIL</a></li>
          <li><a href="#section3">SKIP</a></li>
          <li><a href="#section4">MAIL</a></li>
          <li><a href="#section5">SKIP</a></li>
        </ul>
      </nav>
    </div>
  );
};

export default Menu;
