import React, { useState } from 'react';

const Section1 = () => {
  const [walletAddress, setWalletAddress] = useState('');

  const connectWalletHandler = async () => {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        setWalletAddress(accounts[0]);
      } catch (error) {
        console.error(error);
      }
    } else {
      alert('Please install MetaMask!');
    }
  };

  return (
    <div className="flex flex-col items-center justify-center space-y-8"> {/* Added spacing between boxes */}
      <div className="content-box p-4 mb-4 border border-black bg-gray-500 rounded-lg">
        <h2 className="text-2xl font-bold mb-3 text-center">Yes, We Are Web3 & AI!</h2>
        <p>Discover the limitless possibilities with decentralized applications, smart contracts, and non-fungible tokens. Web3 is not just a technology; it's a movement towards a more transparent, user-empowered internet.</p>
      </div>

      <div className="content-box p-4 border border-black bg-gray-500 rounded-lg">
        {walletAddress ? (
          <div>
            <p className="mb-4">Look, we see your wallet address: <strong>{walletAddress}</strong></p>
            <p> Web3. Explore the decentralized world at your fingertips hosten on central server.</p>
          </div>
        ) : (
          <div className="flex flex-col items-center"> {/* Centering content */}
            <h2 className="text-2xl font-bold mb-3 text-center">Connect Your Wallet</h2>
            <p className="mb-4 text-center">Click the button below to connect your wallet and step into the world of Web3.</p>
            <button 
              onClick={connectWalletHandler} 
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300"
            >
              Connect Wallet
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Section1;
