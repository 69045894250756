
import React, { useEffect, useState } from 'react';
import Image5 from './image5.png';
import Image6 from './image6.png';
import Image7 from './image7.png';
import Image8 from './image8.png';
import './Section2.css'; 

const Section4 = () => {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const dynamicStyle = (base, rate) => ({
    transform: `translateY(${scrollY * rate}px)`,
  });

  return (
<div className="section-container">
<p>SKIP SUCCESS</p>
  <img src={Image5} alt="Description" className="img5" style={dynamicStyle(100, 0.1)} />
  <img src={Image6} alt="Description" className="img6" style={dynamicStyle(200, 0.2)} />
  <img src={Image7} alt="Description" className="img7" style={dynamicStyle(300, 0.3)} />
  <img src={Image8} alt="Description" className="img8" style={dynamicStyle(400, 0.4)} />
</div>
 
  );
};

export default Section4;
